import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  InputWrapper,
  Message,
  ValidationWrapper,
  ErrorWrapper,
  Input,
  Label,
  TextArea,
  ContactButton,
  Controls,
  Select,
  Form,
} from "./contatElements";
import axios from "axios";
import ReactGA from "react-ga4";

const API_PATH = "http://localhost/index.php";

const ContactForm = () => {
  const { t } = useTranslation();
  const [enteredName, setEnteredName] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredMessage, setEnteredMessage] = useState("");
  const [enteredObject, setEnteredObject] = useState("");

  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);

  const [FormStatus, setFormStatus] = useState("Envoyer");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
    setError(false);
    setMailSent(false);
    console.log(event.target.value);
  };
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
    setError(false);
    setMailSent(false);
    console.log(event.target.value);
  };
  const messageChangeHandler = (event) => {
    setEnteredMessage(event.target.value);
    setError(false);
    setMailSent(false);
    console.log(event.target.value);
  };
  const objectChangeHandler = (event) => {
    setEnteredObject(event.target.value);
    setError(false);
    setMailSent(false);
    console.log(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus(t('envoieencours'));

    const { name, email, object, message } = e.target.elements;

    if (!isValidEmail(email.value)) {
      setError(true);

      ReactGA.event({
        category: "Contact us",
        action: "New contact sent",
      });
    } else {
      let conFom = {
        name: name.value,
        email: email.value,
        object: object.value,
        message: message.value,
      };
      console.log(conFom);

      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: conFom,
      })
        .then((result) => {
          if (result.data.sent) {
            setMailSent(result.data.sent);
            setError(false);
          } else {
            setError(true);
          }
        })
        .catch((error) => setError(error.message));

      setFormStatus(t('envoyer'));
      setEnteredName("");
      setEnteredEmail("");
      setEnteredObject("Client");
      setEnteredMessage("");

      ReactGA.event({
        category: "Contact us",
        action: "New contact sent",
      });
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Controls>
        <InputWrapper>
          <Label htmlFor="name">{t("nomprenom")}</Label>
          <Input
            type="text"
            value={enteredName}
            id="name"
            onChange={nameChangeHandler}
            required
          />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            value={enteredEmail}
            onChange={emailChangeHandler}
            id="email"
            required
          />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor="object">{t("objet")}</Label>
          <Select
            type="email"
            id="object"
            value={enteredObject}
            onChange={objectChangeHandler}
            required
          >
            <option value="Client">{t("becomeclient")}</option>
            <option value="Partenaire">{t("becomepartner")}</option>
            <option value="PrizyPro">{t("prizyprominteresse")}</option>
            <option value="Autre">{t("other")}</option>
          </Select>
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor="message">Message</Label>
          <TextArea
            id="message"
            value={enteredMessage}
            onChange={messageChangeHandler}
          />
        </InputWrapper>
      </Controls>

      <ContactButton type="submit">{FormStatus}</ContactButton>

      {mailSent && (
        <ValidationWrapper style={{ visibility: mailSent }}>
          <Message>
            {t("merci")} <br></br>
            {t("demandetraite")}
          </Message>
        </ValidationWrapper>
      )}

      {error && (
        <ErrorWrapper style={{ visibility: error }}>
          <Message>{t("negatifmessage")}</Message>
        </ErrorWrapper>
      )}
    </Form>
  );
};
export default ContactForm;
