import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useTranslation } from "react-i18next";
import {
  NumberWrapper,
  NumberCard,
  NumberSpan,
  Indicateur,
} from "./numbersElements";
import { Container, H1 } from "../../SharedComponents/sharedComponents";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";


const NumberSection = () => {
  const gaEventTracker = useAnalyticsEventTracker(
    "Numbers Section From Landing Page"
  );
  gaEventTracker('scroll to numbers');

  const [counterOn, setCounterOn] = useState(false);
  const { t } = useTranslation();
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <Container color="#eaf7f5">
        <H1 center={1}>{t('landingpage-numbers-title')}</H1>

        <NumberWrapper>
          <NumberCard>
            <h2>
              {counterOn && (
                <CountUp
                  start={0}
                  end={140}
                  duration={2}
                  delay={0}
                  
                >
                  {({ countUpRef }) => (
                    <NumberSpan ref={countUpRef}></NumberSpan>
                  )}
                </CountUp>
              )}
              <NumberSpan color="#e8a1d0">+</NumberSpan>
            </h2>
            <Indicateur>{t('landingpage-numbers-subtitle1')}</Indicateur>
          </NumberCard>

          <NumberCard>
            <h2>
              {counterOn && (
                <CountUp
                  start={0}
                  end={500}
                  duration={2}
                  delay={0}
                 
                >
                  {({ countUpRef }) => (
                    <NumberSpan ref={countUpRef}></NumberSpan>
                  )}
                </CountUp>
              )}
              <NumberSpan color="#e8a1d0">+</NumberSpan>
            </h2>
            <Indicateur>{t('landingpage-numbers-subtitle2')}</Indicateur>
          </NumberCard>

          <NumberCard>
            <h2>
              {counterOn && (
                <CountUp
                  start={0}
                  end={1000}
                  duration={2}
                  delay={0}
                 
                >
                  {({ countUpRef }) => (
                    <NumberSpan ref={countUpRef}></NumberSpan>
                  )}
                </CountUp>
              )}
              <NumberSpan color="#e8a1d0">+</NumberSpan>
            </h2>
            <Indicateur>{t('landingpage-numbers-subtitle3')}</Indicateur>
          </NumberCard>

          <NumberCard>
            <h2>
              {counterOn && (
                <CountUp
                  start={0}
                  end={30}
                  duration={2}
                  delay={0}
                 
                >
                  {({ countUpRef }) => (
                    <NumberSpan ref={countUpRef}></NumberSpan>
                  )}
                </CountUp>
              )}
              <NumberSpan color="#e8a1d0">+</NumberSpan>
            </h2>
            <Indicateur>{t('landingpage-numbers-subtitle4')}</Indicateur>
          </NumberCard>
        </NumberWrapper>
      </Container>
   
    </ScrollTrigger>
  );
};

export default NumberSection;
