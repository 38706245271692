import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LeftRightContainer,
  H1,
  SubTitle,
  BtnWrapper,
  TextColumn,
  ImageColumn,
  Button,
  ArrowForward,
  ArrowRight,
} from "../../SharedComponents/sharedComponents";
import { ImgContact } from "../solutionElements";
import img from "../../../assets/contact.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactGA from 'react-ga4'

const HowToContact = () => {

  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    ReactGA.event({
      category: "How to contact from Solution Page",
      action: "Go to contact",
    });
    

    setHover(!hover);
  };
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
      <LeftRightContainer imgStart={1} pink={1}>
        <TextColumn>
          <H1 center={0}>{t('commentcammander')}</H1>
          <SubTitle center={0}>
            {t('commentcammander-subtitle')}
          </SubTitle>
          <BtnWrapper>
            <Button
              color={"#e9a1ce"}
              dark={0}
              to="/aideetcontact"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t('contact-title')}
              {hover ? <ArrowRight white={0} /> : <ArrowForward white={0} />}
            </Button>
          </BtnWrapper>
        </TextColumn>
        <ImageColumn>
          <ImgContact src={img} alt="Comment contacter - Prizy"></ImgContact>
        </ImageColumn>
      </LeftRightContainer>
    </AnimationOnScroll>
  );
};

export default HowToContact;
