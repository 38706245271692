import React from "react";
import { useTranslation } from "react-i18next";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

import {

  IconContainer,

} from "../../SharedComponents/sharedComponents";
import {
  ContactContent,
  FooterP,
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  Copyrights,
  CopyrightsContainer,
  FooterLogo
} from "./footerElements";

import logo from "../../../assets/logoblanc.png";

const FooterSection = () => {
  const { t } = useTranslation();
  return (
    <>
    
      <Box>

        <Container>
          <Row>
            <Column>
              <FooterLogo src={logo} alt="Logo de Prizy" />
              <FooterP>Incentive, Rewards, Loyalty...</FooterP>
              <FooterP>{t('reinventer')} {t('recompense')} {t('en-entreprise')}</FooterP>
            </Column>
            <Column>
              <Heading>Prizy</Heading>
              <FooterLink to="/solution">{t('apropos')}</FooterLink>
              <FooterLink to="/partners">{t('partners')}</FooterLink>
              <FooterLink to="/solution">{t('modeemploi')}</FooterLink>
              <FooterLink to="/solution">{t('howtopurshase')}</FooterLink>
            </Column>
            <Column>
              <Heading>{t('cards')}</Heading>
              <FooterLink to="/offers" smooth={true} duration={500} spy={true} exact='true'>Limitless Card</FooterLink>
              <FooterLink to="/offers" smooth={true} duration={500} spy={true} exact='true'>Move&Relax Card</FooterLink>
              <FooterLink to="/offers" smooth={true} duration={500} spy={true} exact='true'>ReadyToWear Card</FooterLink>
              <FooterLink to="/offers" smooth={true} duration={500} spy={true} exact='true'>For You Card</FooterLink>
              <FooterLink to="/offers" smooth={true} duration={500} spy={true} exact='true'>Ramadhaniya Card</FooterLink>
              <FooterLink to="/offers" smooth={true} duration={500} spy={true} exact='true'>Lady Card</FooterLink>
            </Column>
            <Column>
              <Heading>{t('prizypro')}</Heading>
              <FooterLink to="/prizypro">{t('aboutthepack')}</FooterLink>
              <FooterLink to="/prizypro">{t('programbenefits')}</FooterLink>
            </Column>
            <Column>
              <Heading>Contacts</Heading>
              <FooterLink to="https://www.linkedin.com/company/fixitplus/" target="_blank">
                <ContactContent>
                  <IconContainer color="pink">
                    <FaLinkedinIn color="black" />
                  </IconContainer>
                  <span style={{ marginLeft: "10px" }}>LinkedIn</span>
                </ContactContent>
              </FooterLink>
              <FooterLink to="https://www.instagram.com/prizy.co/" target="_blank">
                <ContactContent>
                  <IconContainer color="pink">
                    <BsInstagram color="black" />
                  </IconContainer>
                  <span style={{ marginLeft: "10px" }}>Instagram</span>
                </ContactContent>
              </FooterLink>
            </Column>
          </Row>
        </Container>
      </Box>
      
      <CopyrightsContainer>
        <Copyrights>© Prizy, 2023. {t('droits')}.</Copyrights>
      </CopyrightsContainer>
    </>
  );
};

export default FooterSection;
