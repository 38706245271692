import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import img from "../../../assets/phonecards.png";
import {
  LeftRightContainer,
  TextColumn,
  BoldSpan,
  Span,
  H1,
  ImageColumn,
  BtnWrapper,
  Button,
  SubTitle,
  ArrowForward,
  ArrowRight,
} from "../../SharedComponents/sharedComponents";
import { Img } from "./heroElements";

import "../../Solution/WhatIsPrizy/sliderAnimation.css";
import img1 from "../../../assets/sliderAnimationImages/img-1.png";
import img2 from "../../../assets/sliderAnimationImages/img-2.png";
import img3 from "../../../assets/sliderAnimationImages/img-3.png";
import img4 from "../../../assets/sliderAnimationImages/img-4.png";
//import img6 from "../../../assets/sliderAnimationImages/img-6.png";
import img7 from "../../../assets/sliderAnimationImages/img-7.png";


const OffersHero = () => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <LeftRightContainer color="#e8f0ff" imgStart={0}>
        <TextColumn className="animate">
          <H1 center={0}>
            <Span>{t('nos')}</Span>
            <BoldSpan> {t('giftcards')}</BoldSpan>
          </H1>
          <SubTitle center={0}>
            {t('offershero-subtitle')}
          </SubTitle>
          <BtnWrapper>
            <Button
              to="/aideetcontact"
              color={"#EAA1D2"}
              dark={0}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t('devenirclient')}
              {hover ? <ArrowRight white={0} /> : <ArrowForward white={0} />}
            </Button>
          </BtnWrapper>
        </TextColumn>
        <ImageColumn className="animate">
          <Img src={img} alt=""></Img>
          <div className="wrapper">
            <div className="outer">
              <div className="card card1">
                <div className="img">
                  <img src={img1} alt="Limitless Gift Gift Card by Prizy" />
                </div>
              </div>

              <div className="card card2">
                <div className="img">
                  <img src={img2} alt="Ready to Wear Gift Gift Card by Prizy" />
                </div>
              </div>

              <div className="card card3">
                <div className="img">
                  <img src={img3} alt="Move and Relax Gift Gift Card by Prizy" />
                </div>
              </div>

              <div className="card card4">
                <div className="img">
                  <img src={img4} alt="For You Gift Gift Card by Prizy" />
                </div>
              </div>

              <div className="card card5">
                <div className="img">
                  <img src={img7} alt="AZADEA Gift Gift Card by Prizy" />
                </div>
              </div>

             
            </div>
          </div>
        </ImageColumn>
      </LeftRightContainer>
    </>
  );
};

export default OffersHero;
