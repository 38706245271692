import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import Logo from "../../../assets/logo.png";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "../navElements";
import Dropdown from "./dropdown";
import "./dropdown.css";

const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

const Navbar = ({ toggle }) => {
  const [offersdropdown, setoffersDropdown] = useState(false);
  const offersonMouseEnter = () => {
    if (window.innerWidth < 768) {
      setoffersDropdown(false);
    } else {
      setoffersDropdown(true);
    }
  };

  const offersonMouseLeave = () => {
    if (window.innerWidth < 768) {
      setoffersDropdown(false);
    } else {
      setoffersDropdown(false);
    }
  };

  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 5) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    cookies.get("i18next") || "en"
  );
  const currentLangage = languages.find((l) => l.code !== currentLanguageCode);

  const { t } = useTranslation();

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLinks
            to="/"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-70}
          >
            <NavLogo src={Logo} alt="Logo de Prizy" />
          </NavLinks>

          <MobileIcon onClick={toggle}>
            <FaBars></FaBars>
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="/solution"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                {t("solution")}
              </NavLinks>
            </NavItem>
            <NavItem
              onMouseEnter={offersonMouseEnter}
              onMouseLeave={offersonMouseLeave}
            >
              <NavLinks
                to="#"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                {t("offres")}
              </NavLinks>
              {offersdropdown && <Dropdown />}
            </NavItem>

            <NavItem>
              <NavLinks
                to="/partners"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                {t("partners")}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/mobileapp"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                {t("prizyapp")}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/aideetcontact"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                {t("help")}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/blog"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                {t("blog")}
              </NavLinks>
            </NavItem>
          </NavMenu>

          <NavMenu>
            <NavBtn>
              <NavBtnLink to="/aideetcontact">{t("join")}</NavBtnLink>
            </NavBtn>
            <NavItem>
              <NavLinks
                to="#"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-70}
              >
                <p
                  onClick={() => {
                    i18next.changeLanguage(
                      currentLanguageCode === "en" ? "fr" : "en"
                    );
                    setCurrentLanguageCode(
                      currentLanguageCode === "en" ? "fr" : "en"
                    );
                  }}
                >
                  {currentLangage.name}
                </p>
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
