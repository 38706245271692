import React from 'react'
import { Container, H1, SubTitle, BoldSpan, Span } from '../SharedComponents/sharedComponents'

const BlogHero = () => {
  return (
    <Container color='linear-gradient(45deg,#b0e0d6,#d0ead9);'>
        <H1 center={1} className='animate'>
            <Span>Découvrez </Span>
            <BoldSpan>notre Blog</BoldSpan>
        </H1>
        <SubTitle center={1} className='animate'>Ne ratez rien de l'actualité, des informations concernant l'Incentive, le Rewards et le Loyalty Program en entreprise!</SubTitle>
    </Container>
  )
}

export default BlogHero
