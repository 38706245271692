import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import {
  SidebarContainer,
  Icon,
  SidebarWrapper,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  SidebarMenu,
} from "./sidebarElements";

import { FaTimes } from "react-icons/fa";

const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

const Sidebar = ({ isOpen, toggle }) => {
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    cookies.get("i18next") || "en"
  );

  const currentLangage = languages.find((l) => l.code !== currentLanguageCode);

  const { t } = useTranslation();
  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <FaTimes />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/solution" onClick={toggle}>
            {t("solution")}
          </SidebarLink>
          <SidebarLink to="/offers" onClick={toggle}>
            {t("cards")}
          </SidebarLink>
          <SidebarLink to="/prizyPro" onClick={toggle}>
            Prizy Pro
          </SidebarLink>
          <SidebarLink to="/partners" onClick={toggle}>
            {t("partners")}
          </SidebarLink>
          <SidebarLink to="/mobileapp" onClick={toggle}>
            L'appli Prizy
          </SidebarLink>
          <SidebarLink to="/blog" onClick={toggle}>
            Blog
          </SidebarLink>
          <SidebarLink to="/aideetcontact" onClick={toggle}>
            {t("help")}
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to="/aideetcontact" onClick={toggle}>
            {t("join")}
          </SidebarRoute>
        </SideBtnWrap>
        <SidebarLink to="#" onClick={toggle}>
          <p
            onClick={() => {
              i18next.changeLanguage(
                currentLanguageCode === "en" ? "fr" : "en"
              );
              setCurrentLanguageCode(
                currentLanguageCode === "en" ? "fr" : "en"
              );
            }}
          >
            {currentLangage.name}
          </p>
        </SidebarLink>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
