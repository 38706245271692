import React from "react";
import SolutionSection from "../components/LandingPage/Solution";
import OffersSection from "../components/LandingPage/Offers";
import ReviewsSection from "../components/LandingPage/Reviews";
import NumberSection from "../components/LandingPage/Numbers";
import BlogSection from "../components/LandingPage/Blog";
import ClientSection from "../components/LandingPage/Clients";
import HeroSection from "../components/LandingPage/Hero";
import AvantagesSection from "../components/LandingPage/Advantages";
import NewsLetter from "../components/LandingPage/NewsLetter";
import DownloadAppSection from "../components/MobileApp/DownloadApp";
import { Annonce } from "../components/LandingPage/Announcement";
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Prizy | (Re)inventer la Récompense, Fidélisation, Motivation en
          entreprise
        </title>
        <meta
          name="description"
          content="Découvrez Prizy, la solution innovante de cartes cadeaux 100% digitales pour motiver, récompenser et fidéliser vos collaborateurs, partenaires et clients. Profitez d'une expérience shopping exceptionnelle avec notre large réseau de partenaires multi-catégories."
        ></meta>
      </Helmet>
      <Annonce></Annonce>
      <HeroSection></HeroSection>
      <ClientSection></ClientSection>
      <SolutionSection></SolutionSection>
      <OffersSection></OffersSection>
      <NumberSection></NumberSection>
      <AvantagesSection></AvantagesSection>
      <DownloadAppSection></DownloadAppSection>
      <ReviewsSection></ReviewsSection>
      <BlogSection></BlogSection>
      <NewsLetter></NewsLetter>
    </>
  );
};

export default Home;
