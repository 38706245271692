import React from 'react'
import {Review,ReviewCardContainer,ReviewImg,CompanyLogo,Name,Role,LogoWrapper} from './reviewsElements'
import { useTranslation } from "react-i18next";

const ReviewCard = (props) => {
  const { t } = useTranslation();
  return (
    <ReviewCardContainer>
        <ReviewImg src={props.picture} alt={"Témoignage de "+props.name+" - "+t(props.role)+" sur Prizy"}></ReviewImg>
        <Name>{props.name}</Name>
        <Role>{t(props.role)}</Role>
        <Review>{t(props.review)}</Review>
        <LogoWrapper>
            <CompanyLogo src={props.entreprise} alt="company"></CompanyLogo>
        </LogoWrapper>
        
    </ReviewCardContainer>
  )
}

export default ReviewCard
