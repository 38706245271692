import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  SubTitle,
  H1,
} from "../../SharedComponents/sharedComponents";
import {
  ForWhoWrapper,
  ForWhoTitle,
  ForWhoSubTitle,
  ForWhoCard,
} from "./forWhoElements";
import img1 from "../../../assets/forwho1.png";
import img2 from "../../../assets/forwho2.png";
import img3 from "../../../assets/forwho3.png";
import img4 from "../../../assets/forwho4.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

const ForWhoSection = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <AnimationOnScroll animateIn="animate__fadeIn" delay={2} animateOnce={1}>
        <H1 center={1}>{t("forwhotitle")}</H1>
        <SubTitle center={1}>{t("forwhosubtitle")}</SubTitle>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn" delay={2} animateOnce={1}>
        <ForWhoWrapper>
          <ForWhoCard img={img1}>
            <ForWhoTitle>{t("forwho1title")}</ForWhoTitle>
            <ForWhoSubTitle>{t("forwho1subtitle")}</ForWhoSubTitle>
          </ForWhoCard>

          <ForWhoCard img={img2}>
            <ForWhoTitle>{t("forwho2title")}</ForWhoTitle>
            <ForWhoSubTitle>{t("forwho2subtitle")}</ForWhoSubTitle>
          </ForWhoCard>

          <ForWhoCard img={img3}>
            <ForWhoTitle>{t("forwho3title")}</ForWhoTitle>
            <ForWhoSubTitle>{t("forwho3subtitle")}</ForWhoSubTitle>
          </ForWhoCard>

          <ForWhoCard img={img4}>
            <ForWhoTitle>{t("forwho4title")}</ForWhoTitle>
            <ForWhoSubTitle>{t("forwho4subtitle")}</ForWhoSubTitle>
          </ForWhoCard>
        </ForWhoWrapper>
      </AnimationOnScroll>
    </Container>
  );
};

export default ForWhoSection;
