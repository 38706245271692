import React from "react";
import { useTranslation } from "react-i18next";
import AvantageOneTwo from "../components/PrizyPro/AdvantageOneTwo";
import AvantageThree from "../components/PrizyPro/AdvantageThree";
import PrizyProHero from "../components/PrizyPro/Hero";
import JoinUsBanner from "../components/SharedComponents/joinUsBanner";
import { Helmet } from 'react-helmet';

const PrizyPro = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Prizy Pro | Créez vos Propres Cartes Cadeaux</title>
        <meta name="description" content="Découvrez Prizy Pro, une solution marque blanche qui permet à nos partenaires de créer et de gérer leurs propres cartes cadeaux, personnalisées selon leurs besoins." />
      </Helmet>
      <PrizyProHero></PrizyProHero>
      <AvantageOneTwo></AvantageOneTwo>
      <AvantageThree></AvantageThree>
      <JoinUsBanner
        pink={1}
        sousTitre={t("integrateprizypro")}
        buttonText={t("join")}
      ></JoinUsBanner>
    </>
  );
};

export default PrizyPro;
