import React from "react";
import AdvantagesSection from "../components/MobileApp/Advantages";
import DownloadAppSection from "../components/MobileApp/DownloadApp";
import FunctionnalitiesSection from "../components/MobileApp/Functionnalities";
import AppHero from "../components/MobileApp/Hero";
import { Helmet } from 'react-helmet';

const MobileApp = () => {
  return (
    <>
      <Helmet>
        <title>Prizy App | Explorez des Fonctionnalités Innovantes pour Gérer vos Récompenses</title>
        <meta name="description" content="Découvrez les fonctionnalités de l'application Prizy pour gérer vos récompenses et optimiser votre fidélisation client." />
      </Helmet>
      <AppHero></AppHero>
      <AdvantagesSection></AdvantagesSection>
      <FunctionnalitiesSection></FunctionnalitiesSection>
      <DownloadAppSection></DownloadAppSection>
    </>
  );
};

export default MobileApp;
