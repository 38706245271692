import React from 'react'
import { useTranslation } from "react-i18next";
import CardsSection from '../components/Offers/Cards';
import ForWhoSection from '../components/Offers/ForWho';
import OffersHero from '../components/Offers/Hero';
import JoinUsBanner from '../components/SharedComponents/joinUsBanner';
import { Helmet } from 'react-helmet';

const Offers=()=> {

  const { t } = useTranslation();
  return (
    <>
        <Helmet>
          <title>Offres de Cartes Cadeaux Prizy | Récompensez vos Collaborateurs</title>
          <meta name="description" content="Découvrez nos offres de cartes cadeaux Prizy, la solution idéale pour fidéliser vos collaborateurs et dynamiser votre programme de récompenses. Offrez une expérience inoubliable avec nos cartes cadeaux multi-enseignes." />
        </Helmet>
        <OffersHero></OffersHero>
        <CardsSection></CardsSection>
        <ForWhoSection></ForWhoSection>
        <JoinUsBanner pink={1} sousTitre={t('integrateclients')} buttonText={t('devenirclient')}></JoinUsBanner>
    </>
  )
}

export default Offers;