import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useTranslation } from "react-i18next";
import {AvantagesContainer,Img,AvantageTitle} from './functionnalitiesElements'
import { IconContainer,H1,LeftRightContainer,SubTitle,AdvantageWrapper,AdvantageContent,AvantageText,TextColumn,ImageColumn} from '../../SharedComponents/sharedComponents'
import { avantagesPrizyApp } from './featuresData'
import apppic from "../../../assets/appphone.png"



const FunctionnalitiesSection = () => {

  const { t } = useTranslation();
  return (
    <>
    <AvantagesContainer>
        <AnimationOnScroll animateIn="animate__fadeIn" delay={2} animateOnce={1}>

            <H1 center={1} style={{ paddingLeft : "5%", paddingRight : "2%" }}>{t("appfuncttitle")}</H1>
            <SubTitle center={1} style={{ paddingLeft : "5%", paddingRight : "5%" }}>{t("appfunctsubtitle")}</SubTitle>

        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
                    <LeftRightContainer color={"#e9f7f5"} imgStart={1}>
                                <TextColumn>
                                    
                                    {avantagesPrizyApp.map((avantage, index1) => {
                                            return (
                                                <AdvantageContent pink={1}>
                                                    <IconContainer color={avantage.color}>
                                                        {avantage.icon}
                                                    </IconContainer>
                                                    <AdvantageWrapper>
                                                        <AvantageTitle>{t(avantage.titre)}</AvantageTitle>
                                                        <AvantageText>{t(avantage.sousTitre)}</AvantageText>
                                                    </AdvantageWrapper>
                                                    
                                                </AdvantageContent>
                                            );
                                    })}

                                </TextColumn>
                                <ImageColumn>
                                    <Img src={apppic} alt="Fonctionnalités de l'application mobile Prizy"></Img>
                                </ImageColumn>
                    </LeftRightContainer>
                </AnimationOnScroll>
        
       
    </AvantagesContainer>
    
        
      
    </>
  )
}

export default FunctionnalitiesSection
