import React from 'react'
import HowDoesItWork from '../components/Solution/HowItWorks';
import HowToContact from '../components/Solution/Contact';
import SolutionHero from '../components/Solution/Hero';
import Values from '../components/Solution/Values';
import WhatisPrizy from '../components/Solution/WhatIsPrizy';
import PrizyProSection from '../components/Solution/PrizyPro';
import { Helmet } from 'react-helmet';


const SolutionPage=()=> {

  return (
    <>
        <Helmet>
          <title>Prizy | Votre Solution de Récompense Innovante</title>
          <meta name="description" content="Explorez Prizy, une solution complète de gestion des récompenses et de fidélisation d'entreprise." />
        </Helmet>
        
        <SolutionHero></SolutionHero>
        <Values></Values> 
        <WhatisPrizy></WhatisPrizy>
        <HowDoesItWork></HowDoesItWork>
        <PrizyProSection></PrizyProSection>
        <HowToContact></HowToContact>
    </>
  )
}

export default SolutionPage;
