import React from 'react'
import ContactSection from '../components/ContactUs';
import FAQSection from '../components/QA';
import { Helmet } from 'react-helmet';


const Contact=()=> {

  return (
    <>
      <Helmet>
        <title>Contactez Prizy | Disponibilité 7j/7</title>
        <meta name="description" content="Avez-vous des questions sur nos solutions ? Contactez l'équipe Prizy pour des réponses rapides et un soutien personnalisé." />
      </Helmet>
      <ContactSection></ContactSection>
      <FAQSection></FAQSection>
    </>
  )
}

export default Contact;