import React from 'react'
import Blog from '../components/Blog'
import { Helmet } from 'react-helmet';


const BlogPage = () => {
  return (
    <>
      <Helmet>
        <title>Blog Prizy | Conseils et tendances</title>
        <meta name="description" content="Visitez notre blog pour des conseils pratiques, des études de cas et des tendances sur la fidélisation des clients et les programmes de récompenses. Restez informé avec Prizy !" />
      </Helmet>
      <Blog>
      </Blog>
    </>
  )
}
export default BlogPage
