import React from "react";
import {
  ArticleContentContainer,
  ArticleParagraph,
  ArticleUl,
  Articleli,
} from "./blogElements";
import { Container } from "../SharedComponents/sharedComponents";
import ArticleBanner from "./articleBanner";
import img from "../../assets/article6banner.png";
import ReactGA from "react-ga4";

const Article7 = () => {
  ReactGA.event({
    category: "L'importance des activités entre collaborateurs",
    action: "Blog article",
  });
  return (
    <Container>
      <ArticleBanner
        title={"L'importance des activités entre collaborateurs"}
        date={"30 Avril 2023"}
        time={"3"}
        img={img}
      ></ArticleBanner>
      <ArticleContentContainer>
        <ArticleParagraph>
          L'esprit d'équipe et la collaboration sont essentiels pour favoriser
          une dynamique de travail harmonieuse au sein de l'entreprise. Par
          exemple, des activités de team-building peuvent être mises en place,
          comme des jeux de groupe, des défis ou des ateliers de résolution de
          problèmes en équipe, tels qu'un escape game ou une course
          d'orientation. Les activités sportives peuvent également être
          encouragées, comme le yoga, le football, ou même des compétitions
          amicales. Les activités créatives, telles que la peinture, la poterie
          ou la musique, peuvent également être organisées pour favoriser
          l'expression artistique et la détente. Ces activités offrent aux
          collaborateurs l'opportunité de mieux se connaître, de créer des liens
          forts au-delà du cadre professionnel et de favoriser une meilleure
          collaboration, contribuant ainsi à créer un environnement de travail
          positif. De plus, une bonne entente entre les membres d'une équipe est
          essentielle pour le bon fonctionnement d'une entreprise.
        </ArticleParagraph>
        <ArticleParagraph>
          Dans ce contexte, examinons de plus près pourquoi il est important
          d'avoir des activités entre collaborateurs.
        </ArticleParagraph>
        <ArticleUl>
          <Articleli>
            <b>Renforcement des relations :</b> les activités renforcent les
            relations sociales et professionnelles au sein de l'équipe, créant
            ainsi un environnement de travail harmonieux.
          </Articleli>
          <Articleli>
            <b>Cohésion d'équipe :</b> les activités favorisent la cohésion
            d'équipe en encourageant la collaboration, la communication et
            l'efficacité de l'équipe.
          </Articleli>
          <Articleli>
            <b>Motivation et engagement :</b> les activités renforcent la
            motivation et l'engagement des employés en offrant des moments de
            détente et de plaisir, montrant ainsi que l'entreprise se soucie de
            leur bien-être.
          </Articleli>
          <Articleli>
            <b>Développement personnel :</b> les activités peuvent contribuer au
            développement professionnel et personnel des employés en leur
            permettant d'apprendre de nouvelles compétences et de renforcer leur
            confiance en eux.
          </Articleli>
          <Articleli>
            <b>Gestion du stress et bien-être :</b> Les activités aident à gérer
            le stress en offrant une pause bienvenue pour se détendre, se
            divertir et se ressourcer, contribuant ainsi à améliorer le
            bien-être des employés.
          </Articleli>
        </ArticleUl>

        <ArticleParagraph>
          En conclusion, il est clair que les activités entre collaborateurs ont
          un impact significatif sur la dynamique de l'équipe en favorisant la
          bonne entente, la cohésion d'équipe, la motivation et le développement
          personnel. De plus, ces activités peuvent également contribuer à une
          hausse de la productivité en renforçant les relations professionnelles
          et en améliorant la collaboration au sein de l'équipe. Il est donc
          essentiel que les managers reconnaissent l'importance de ces activités
          et prennent l'initiative de les organiser régulièrement dans le cadre
          du travail d'équipe. En investissant dans ces activités, les managers
          peuvent créer un environnement de travail positif, stimulant et
          productif, où les employés se sentent valorisés, motivés et engagés,
          ce qui peut avoir un impact positif sur la performance globale de
          l'entreprise.
        </ArticleParagraph>
      </ArticleContentContainer>
    </Container>
  );
};

export default Article7;
