import React,{useState} from 'react'
import { useTranslation } from "react-i18next";
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';

import { SubTitle,H1,BannerContainer, BtnWrapper,ArrowForward,ArrowRight,Button} from './sharedComponents'
import ReactGA from 'react-ga4'

const JoinUsBanner = (props) => {
  const { t } = useTranslation();
  const gaEventTracker = useAnalyticsEventTracker(
    "Join us banner"
  );
  const  [hover,setHover]=useState(false)
  const onHover=()=>{
    gaEventTracker("Go to contact");
    ReactGA.event({
      category: "Join us banner",
      action: "Go to contact",
     
    });
    setHover(!hover)
  }

  return (
    <>
        <BannerContainer pink={props.pink}>
            
            <H1 center={1}>{t('join')}</H1>
            <SubTitle center={1}>{t('join-subtitle')} <br></br> {props.sousTitre}</SubTitle>
           
            <BtnWrapper>
                <Button color={'#0a0a0a'} dark={1} to="/aideetcontact"  onMouseEnter={onHover} onMouseLeave={onHover}>
                    {props.buttonText} { hover? <ArrowRight white={1}/>:<ArrowForward white={1}/>}
                </Button>
            </BtnWrapper>
            
        </BannerContainer>
    </>

        
  )
}

export default JoinUsBanner
