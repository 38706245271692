import React from 'react'
import { useTranslation } from "react-i18next";
import PrizyPartnersHero from '../components/Partners/Hero';
import PartnersList from '../components/Partners/PartnersList';
import JoinUsBanner from '../components/SharedComponents/joinUsBanner';
import { Helmet } from 'react-helmet';

const Partners=()=> {
  const { t } = useTranslation();

  return (
    <>
        <Helmet>
          <title>Nos Partenaires Prizy | Enseignes et Magasins</title>
          <meta name="description" content="Consultez la liste de nos partenaires et découvrez comment Prizy collabore avec des marques renommées pour offrir des cartes cadeaux de qualité. Ensemble, nous renforçons l'expérience client." />
        </Helmet>
        <PrizyPartnersHero></PrizyPartnersHero>
        <PartnersList></PartnersList>
        <JoinUsBanner pink={0} sousTitre={t('integratepartners')} buttonText={t('devenirpartenaire')}></JoinUsBanner>
    </>
  )
}

export default Partners;