import React from 'react'
import { useTranslation } from "react-i18next";
import img from '../../../assets/ForYou.png'
import { Img } from './cardElements'
import { LeftRightContainer,TextColumn,ImageColumn,H1,BoldSpan,Span,SubTitle } from '../../SharedComponents/sharedComponents'
import { AnimationOnScroll } from 'react-animation-on-scroll'




const ForYou = () => {
  
  const { t } = useTranslation();
  return (
    <>
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
    <LeftRightContainer color={'#f0e5ff'} imgStart={1}>
            <TextColumn>
                <H1 center={0}>
                    <BoldSpan>FOR YOU </BoldSpan>
                    <Span>CARD.</Span>
                </H1>
                <SubTitle>{t('foryousubtitle')}</SubTitle>  
                   
            </TextColumn>
            <ImageColumn>
                <Img src={img} alt="For You Gift Gift Card by Prizy"></Img>
            </ImageColumn>
    </LeftRightContainer>
    </AnimationOnScroll>
    

    </>
  )
}

export default ForYou