import React from 'react'
import { useTranslation } from "react-i18next";
import FAQ from './qa'
import { SubTitle,H1,TextColumn,ImageColumn, LeftRightContainer} from '../SharedComponents/sharedComponents'
import { AnimationOnScroll } from 'react-animation-on-scroll'

const FAQSection = () => {
    const { t } = useTranslation();
    return (
        <>
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
            <LeftRightContainer color='#e9f7f5' imgStart={0}>
                        <TextColumn>
                            <H1 center={0}>
                                {t('faq-title')}
                            </H1>
                            <SubTitle center={0}>{t('faq-subtitle')}</SubTitle>
                        </TextColumn>
                        <ImageColumn>
                            <FAQ></FAQ>
                        </ImageColumn>
            </LeftRightContainer>
        </AnimationOnScroll>
          
        </>
      )
}

export default FAQSection
