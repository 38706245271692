import {
  SuperWalletIcon,
  BallanceIcon,
  TransactionsIcon,
  StoresIcon,
  NotificationIcon,
} from "./functionnalitiesElements";

export const avantagesPrizyApp = [
  {
    icon: <SuperWalletIcon></SuperWalletIcon>,
    color: "#cdfae3",
    titre: "appfunct1title",
    sousTitre: "appfunct1subtitle",
  },
  {
    icon: <BallanceIcon></BallanceIcon>,
    color: "#ffd9ed",
    titre: "appfunct2title",
    sousTitre: "appfunct2subtitle",
  },
  {
    icon: <TransactionsIcon></TransactionsIcon>,
    color: "#c9faf8",
    titre: "appfunct3title",
    sousTitre: "appfunct3subtitle",
  },
  {
    icon: <StoresIcon></StoresIcon>,
    color: "#f9d3ff",
    titre: "appfunct5title",
    sousTitre: "appfunct5subtitle",
  },
  {
    icon: <NotificationIcon></NotificationIcon>,
    color: "#ffdede",
    titre: "appfunct6title",
    sousTitre: "appfunct6subtitle",
  },
];
