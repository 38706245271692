import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LeftRightContainer,
  Button,
  BtnWrapper,
  H1,
  ImageColumn,
  TextColumn,
  SubTitle,
  ArrowForward,
  ArrowRight,
} from "../../SharedComponents/sharedComponents";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "./sliderAnimation.css";
import img1 from "../../../assets/sliderAnimationImages/img-1.png";
import img2 from "../../../assets/sliderAnimationImages/img-2.png";
import img3 from "../../../assets/sliderAnimationImages/img-3.png";
import img4 from "../../../assets/sliderAnimationImages/img-4.png";
import img7 from "../../../assets/sliderAnimationImages/img-7.png";

import ReactGA from "react-ga4";

const WhatisPrizy = () => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);

    ReactGA.event({
      category: "What is Pirzy from Solution Page",
      action: "Go to Partners",
    });
  };
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
      <LeftRightContainer color={"#FFF5F9"} imgStart={0}>
        <TextColumn>
          <H1 center={0}>{t("cards")}</H1>
          <SubTitle center={0}>{t("whatisprizysubtitle1")}</SubTitle>
          <SubTitle center={0}>{t("whatisprizysubtitle2")}</SubTitle>
          <SubTitle center={0}>{t("whatisprizysubtitle3")}</SubTitle>
          <BtnWrapper>
            <Button
              color={"#A1CEC6"}
              dark={0}
              to="/partners"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t('voirenseignes')}
              {hover ? <ArrowRight white={0} /> : <ArrowForward white={0} />}
            </Button>
          </BtnWrapper>
        </TextColumn>
        <ImageColumn>
          <div class="wrapper">
            <div class="outer">
              <div class="card card1">
                <div class="img">
                  <img src={img1} alt="Limitless Gift Gift Card by Prizy" />
                </div>
              </div>

              <div class="card card2">
                <div class="img">
                  <img src={img2} alt="Ready to Wear Gift Card by Prizy" />
                </div>
              </div>

              <div class="card card3">
                <div class="img">
                  <img src={img3} alt="Move and Relax Gift Card by Prizy" />
                </div>
              </div>

              <div class="card card4">
                <div class="img">
                  <img src={img4} alt="ForYou Gift Card by Prizy" />
                </div>
              </div>

              <div class="card card5">
                <div class="img">
                  <img src={img7} alt="Azadea Gift Card by Prizy" />
                </div>
              </div>
            </div>
          </div>
        </ImageColumn>
      </LeftRightContainer>
    </AnimationOnScroll>
  );
};

export default WhatisPrizy;
