import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LeftRightContainer,
  H1,
  SubTitle,
  TextColumn,
  BtnWrapper,
} from "../../SharedComponents/sharedComponents";
import {
  ImgPrizyPro,
  ImageColumnWithoutMargins,
} from "../../Solution/solutionElements";

import img from "../../../assets/downloadprizy-2.png";
import playStore from "../../../assets/play_store.svg";
import appStore from "../../../assets/app_store.svg";

import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactGA from "react-ga4";

const DownloadAppSection = () => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    ReactGA.event({
      category: "Download the app",
      action: "Download the app",
    });
    setHover(!hover);
  };
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
      <LeftRightContainer color="#EAA1D2" imgStart={0} pink={0}>
        <TextColumn>
          <H1 center={0} white={0}>
            {t("downloadapptitle")}
          </H1>
          <SubTitle center={0} white={0}>
            {t("downloadappsubtitle")}
          </SubTitle>

          <BtnWrapper>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://play.google.com/store/apps/details?id=co.prizy.prizyapp.prizyapp"
            >
              <img
                src={playStore}
                alt="Play Store"
                aria-hidden="true"
                loading="eager"
                fetchpriority="high"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://apps.apple.com/dz/app/prizy/id6475423335"
            >
              <img
                src={appStore}
                alt="Apple Store"
                aria-hidden="true"
                loading="eager"
                fetchpriority="high"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              />
            </a>
          </BtnWrapper>
        </TextColumn>
        <ImageColumnWithoutMargins>
          <ImgPrizyPro
            src={img}
            alt="Téléchargez l'application Prizy"
          ></ImgPrizyPro>
        </ImageColumnWithoutMargins>
      </LeftRightContainer>
    </AnimationOnScroll>
  );
};

export default DownloadAppSection;
