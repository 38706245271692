import styled from "styled-components";
import { cardsharedStyles, cardsWrappersharedStyles } from "../../SharedComponents/sharedComponents";



export const ForWhoWrapper=styled.div`
    margin:2rem auto;
    ${cardsWrappersharedStyles}
`;


export const ForWhoCard=styled.div`
    ${cardsharedStyles};
    min-height:500px;
    max-width:350px;
    
`;


export const ForWhoTitle=styled.h2`
    font-size:1.5rem;
    font-weight:700;
    color:#fff;
    margin-bottom:10px;
    
    @media screen and (max-width:480px){
        font-size:1rem;
    }

`;

export const ForWhoSubTitle=styled.p`
    font-size:1rem;
    font-weight:400;
    color:#fff;
    margin-bottom:10px;
    @media screen and (max-width:480px){
        font-size:0.8rem;
    }
   

`;



