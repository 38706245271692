import React from "react";
import { useTranslation } from "react-i18next";
import img from "../../../assets/limitless.png";
import {
  Img,
  PartnersTitle,
  PartnersWrapper,
  CardPartnersWrapper,
} from "./cardElements";
import {
  LeftRightContainer,
  TextColumn,
  ImageColumn,
  H1,
  BoldSpan,
  Span,
  SubTitle,
} from "../../SharedComponents/sharedComponents";
import { partners } from "../../Partners/PartnersList/partnersData";
import PartnerCard from "../../Partners/PartnersList/partnerCard";
import { AnimationOnScroll } from "react-animation-on-scroll";

const LimitlessCard = () => {
  const { t } = useTranslation();
  const partnersList = partners;
  return (
    <>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        delay={2}
        animateOnce={1}
      >
        <LeftRightContainer id="limitless" imgStart={1}>
          <TextColumn>
            <H1 center={0}>
              <BoldSpan>Limitless </BoldSpan>
              <Span>CARD.</Span>
            </H1>
            <SubTitle>{t("limitless-subtitle1")}</SubTitle>
            <SubTitle>{t("limitless-subtitle2")}</SubTitle>
          </TextColumn>
          <ImageColumn>
            <Img src={img} alt="Limitess Gift Gift Card by Prizy"></Img>
          </ImageColumn>
        </LeftRightContainer>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateIn="animate__fadeInRight"
        delay={2}
        animateOnce={1}
      >
        <PartnersWrapper>
          <PartnersTitle>{t("ou-puis-je-consommer")}</PartnersTitle>
          <CardPartnersWrapper>
            {partnersList.map((data, index) => {
              return (
                <PartnerCard
                  key={data.id}
                  cover={data.cover}
                  logo={data.logo}
                  name={data.name}
                  teaser={data.teaser}
                  teaserEn={data.teaserEn}
                ></PartnerCard>
              );
            })}
          </CardPartnersWrapper>
        </PartnersWrapper>
      </AnimationOnScroll>
    </>
  );
};

export default LimitlessCard;
