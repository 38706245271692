import bloc1 from "../../assets/bloc1.png"
import bloc2 from "../../assets/bloc2.png"
import {Icon1,Icon3,Icon4,Icon5,Icon6,Icon7,Icon8} from "./advantagesElements"



export const avantagesPrizyPro=[
    {
        imageStart:0,
        color:'#fff',
        title:["ameliorer","userexperience"],
        imagelink:bloc1,
        avantages:[
            {
                icon:<Icon1></Icon1>, 
                color:"#ffd9ed",
                sousTitre:"prizypro-avantage1-subtitle1",
            },
            {
                icon:<Icon3></Icon3>, 
                color:"#f78aa9",
                sousTitre:"prizypro-avantage1-subtitle2",
            },
            {
                icon:<Icon4></Icon4>, 
                color:"#f7be74",
                sousTitre:"prizypro-avantage1-subtitle3",
            },
            
        ]

        
    },
    {
        imageStart:1,
        color:'#e6f7f3',
        title:["augmentez","chiffreaffaire"],
        imagelink:bloc2,
        avantages:[
            {
                icon:<Icon5></Icon5>, 
                color:"#fcc19f",
                sousTitre:"prizypro-avantage2-subtitle1",
            },
            {
                icon:<Icon6></Icon6>, 
                color:"#fdf6bb",
                sousTitre:"prizypro-avantage2-subtitle2",
            },
            {
                icon:<Icon7></Icon7>, 
                color:"#f9d3ff",
                sousTitre:"prizypro-avantage2_subtitle3",
            },
            {
                icon:<Icon8></Icon8>, 
                color:"#add6cd",
                sousTitre:"prizypro-avantage2-subtitle4",
            }
        ]

        
    },
   
]