import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import img from "../../../assets/appheroimage.png";
import { Img, LeftRightContainer } from "./heroElements";
import playStore from "../../../assets/play_store.svg";
import appStore from "../../../assets/app_store.svg";

import {
  TextColumn,
  ImageColumn,
  BtnWrapper,
  H1,
  BoldSpan,
  SubTitle,
} from "../../SharedComponents/sharedComponents";

const AppHero = () => {
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <LeftRightContainer
        color="linear-gradient(45deg,#ffaebc,#e8a1d0);"
        imgStart={0}
      >
        <TextColumn className="animate">
          <H1 center={0}>
            <BoldSpan>{t("prizyapp-title")}</BoldSpan>
          </H1>
          <SubTitle center={0}>{t("prizyapp-subtitle")}</SubTitle>
          <BtnWrapper>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://play.google.com/store/apps/details?id=co.prizy.prizyapp.prizyapp"
            >
              <img
                src={playStore}
                alt="Play Store"
                aria-hidden="true"
                loading="eager"
                fetchpriority="high"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://apps.apple.com/dz/app/prizy/id6475423335"
            >
              <img
                src={appStore}
                alt="Apple Store"
                aria-hidden="true"
                loading="eager"
                fetchpriority="high"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              />
            </a>
          </BtnWrapper>
        </TextColumn>
        <ImageColumn className="animate">
          <Img src={img} alt="Application mobile Prizy"></Img>
        </ImageColumn>
      </LeftRightContainer>
    </>
  );
};

export default AppHero;
