import React from 'react'
import { Container } from '../SharedComponents/sharedComponents'
import AbstractsComponent from './abstractComponent'
import BlogHero from './hero'

const Blog = () => {
  return (
    <>
      <BlogHero></BlogHero>
      <Container>
        <AbstractsComponent></AbstractsComponent>
      </Container>
      
    </>
    
  )
}

export default Blog;
