import React from "react";
import { useTranslation } from "react-i18next";
import img from "../../../assets/readytowear.png";
import {
  Img,
  PartnersTitle,
  PartnersWrapper,
  CardPartnersWrapper,
} from "./cardElements";
import {
  LeftRightContainer,
  TextColumn,
  ImageColumn,
  H1,
  BoldSpan,
  Span,
  SubTitle,
} from "../../SharedComponents/sharedComponents";
import { partners } from "../../Partners/PartnersList/partnersData";
import PartnerCard from "../../Partners/PartnersList/partnerCard";
import { AnimationOnScroll } from "react-animation-on-scroll";

const ReadyToWear = () => {
  const { t } = useTranslation();
  const partnersList = partners.filter((partner) => {
    return partner.includedGiftCardTypes.includes("readytowear card");
  });

  return (
    <>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        delay={2}
        animateOnce={1}
      >
        <LeftRightContainer color="" imgStart={0}>
          <TextColumn>
            <H1 center={0}>
              <BoldSpan>READY </BoldSpan>
              <Span>To </Span>
              <BoldSpan>WEAR </BoldSpan>
              <Span>CARD.</Span>
            </H1>
            <SubTitle>{t("readytowearsubtitle")}</SubTitle>
          </TextColumn>
          <ImageColumn>
            <Img src={img} alt="Ready To Wear Gift Gift Card by Prizy"></Img>
          </ImageColumn>
        </LeftRightContainer>
      </AnimationOnScroll>

      <AnimationOnScroll
        animateIn="animate__fadeInRight"
        delay={2}
        animateOnce={1}
      >
        <PartnersWrapper>
          <PartnersTitle>{t("ou-puis-je-consommer")}</PartnersTitle>
          <CardPartnersWrapper>
            {partnersList.map((data, index) => {
              return (
                <PartnerCard
                  key={data.id}
                  cover={data.cover}
                  logo={data.logo}
                  name={data.name}
                  teaser={data.teaser}
                  teaserEn={data.teaserEn}
                ></PartnerCard>
              );
            })}
          </CardPartnersWrapper>
        </PartnersWrapper>
      </AnimationOnScroll>
    </>
  );
};

export default ReadyToWear;
